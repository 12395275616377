<template>
  <div class="app-box">
      <h3 class="titleH3">确认本人操作</h3>
      <p class="titleP">请保持正脸在取景框中</p>
      <div class="imgBoxke">
          <img src="../../assets/img/rlsbimg.png" alt="">
      </div>
      <div class="flexBox">
          <div class="li">
              <img src="../../assets/img/rlsb3.png" alt="">
              <p>正对手机</p>
          </div>
          <div class="li">
              <img src="../../assets/img/rlsb2.png" alt="">
              <p>光线充足</p>
          </div>
          <div class="li">
              <img src="../../assets/img/rlsb1.png" alt="">
              <p>脸无遮挡</p>
          </div>
      </div>
      <!-- end -->
      <div class="pokeoBox">
          <van-button type="info" block @click="goRouter('/face')">开始人脸识别</van-button>
      </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #EDF2F5FF;
  box-sizing: border-box;
  overflow: hidden;
}
.titleH3{
    text-align: center;
    font-size: .44rem;
    margin-top: 1rem;
}
.titleP{
    text-align: center;
    line-height: 1rem;
    color: #5F6781FF;
    font-size: .4rem;
}
.imgBoxke img{
    width: 5rem;
    height: 5rem;
    margin: auto;
    margin-top: 2rem;
}
.flexBox{
    display: flex;
    text-align: center;
    margin: .5rem 0;
}
.flexBox .li{
flex: 1;
text-align: center;
margin: auto;
color: #5F6781FF;
}
.flexBox .li p{
    line-height: .8rem;
}
.flexBox .li img{
    margin: auto;
    width: 1rem;
}
.pokeoBox{
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translate(-50%,0);
}
.pokeoBox ::v-deep.van-button{
    border-radius: 10px;
}
</style>